export const ROUTES = {
  FRIENDS: '/friends',
  PROFILE: '/profile',
  PUBLIC_PROJECTS: '/projects',
  PROJECT_PAGE: '/projects/:id',
  HOME: '/',
  MY_PROJECTS: '/my-projects',
  CREATE_PROJECT: '/create-project',
  PROFILE_SETTINGS: '/profile/settings',
  PROJECT_DETAILS: '/projects/:id/details',
  LOGS: '/projects/:projectId/logs/:userId',
  EDIT: '/projects/:id/edit',
  AUTOTASKS: '/projects/autotasks',
  UNAUTHORIZED: '/unauthorized',
  TUTORIAL: '/tutorial',
  WALLET: '/wallet',
  BECOME_ADVERTISER: '/become-partner',
  ALL_TASKS: '/all-tasks',
  POST_MEME: '/post-meme',
};
