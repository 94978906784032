export default function RadditOutlined({ style }: { style: React.CSSProperties }) {
  return (
    <svg
      style={style}
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 20.805 20.805'
      xmlSpace='preserve'
    >
      <g>
        <path
          d='M19.522,7.196c-1.062-1.064-2.775-1.077-3.896-0.069c-1.305-0.627-2.815-1.026-4.437-1.121
		c0.6-1.862,1.834-4.465,3.077-4.715c0.418-0.083,0.874,0.137,1.353,0.657c-0.035,0.138-0.061,0.28-0.061,0.427
		c0,0.921,0.748,1.669,1.669,1.669c0.921,0,1.669-0.746,1.669-1.669c0-0.921-0.748-1.667-1.669-1.667
		c-0.3,0-0.581,0.086-0.825,0.226c-0.758-0.753-1.558-1.055-2.386-0.89c-2.417,0.487-3.88,5.023-4.157,5.955
		C8.152,6.076,6.563,6.485,5.202,7.146c-1.119-1.031-2.849-1.022-3.92,0.049C0.227,8.25,0.207,9.942,1.189,11.064
		c-0.319,0.731-0.498,1.512-0.498,2.324c0,4.089,4.371,7.417,9.744,7.417s9.746-3.327,9.746-7.417c0-0.831-0.189-1.628-0.522-2.375
		C20.597,9.893,20.561,8.235,19.522,7.196z M1.731,7.645C2.506,6.869,3.73,6.818,4.598,7.467c-1.344,0.769-2.422,1.79-3.101,2.977
		C0.904,9.58,0.977,8.398,1.731,7.645z M10.435,19.531c-4.673,0-8.473-2.755-8.473-6.143c0-3.385,3.8-6.142,8.473-6.142
		c4.674,0,8.474,2.757,8.474,6.142C18.909,16.778,15.109,19.531,10.435,19.531z M16.234,7.446c0.868-0.627,2.071-0.567,2.838,0.2
		c0.742,0.74,0.825,1.891,0.27,2.749C18.656,9.217,17.578,8.205,16.234,7.446z M6.886,13.284c-0.877,0-1.589-0.711-1.589-1.589
		c0-0.877,0.711-1.589,1.589-1.589c0.878,0,1.589,0.711,1.589,1.589S7.763,13.284,6.886,13.284z M15.56,11.694
		c0,0.877-0.711,1.589-1.589,1.589c-0.877,0-1.589-0.711-1.589-1.589c0-0.878,0.711-1.589,1.589-1.589
		C14.848,10.105,15.56,10.816,15.56,11.694z M14.222,15.912c0.217,0.275,0.167,0.676-0.112,0.893
		c-1.326,1.028-2.614,1.375-3.742,1.375c-2.255,0-3.874-1.378-3.898-1.4c-0.264-0.228-0.291-0.632-0.061-0.896
		c0.231-0.264,0.63-0.291,0.894-0.063c0.118,0.101,2.884,2.42,6.027-0.02C13.606,15.584,14.005,15.637,14.222,15.912z'
        />
      </g>
    </svg>
  );
}
